@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./assets/fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url("./assets/fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "NotoSans-Regular";
  font-size: 16px;
}

#root {
  background-image: url("./assets/background.jpg");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.text-NotoSans-Regular {
  font-family: "NotoSans-Regular";
}

.text-NotoSans-Bold {
  font-family: "NotoSans-Bold";
}
